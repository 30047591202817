
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GrassIcon from '@mui/icons-material/Grass';
import FlightIcon from '@mui/icons-material/Flight';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import BoltIcon from '@mui/icons-material/Bolt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SpaIcon from '@mui/icons-material/Spa';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import SchoolIcon from '@mui/icons-material/School';

export const services = [
  {
    id: 'cloud-services',
    img: "/services/nft-creation.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/cloud.png",
    title: "Cloud Services",
    color: "#f7be77",
    content:
      `Techbrain Networks offers a comprehensive range of cloud services to help businesses harness the full potential of cloud computing. With our Infrastructure as a Service (IaaS), businesses can easily scale their computing resources and eliminate the need for physical hardware. Our Platform as a Service (PaaS) provides a seamless development and deployment environment, accelerating application development. Through Software as a Service (SaaS), businesses can access and use powerful software applications without the hassle of installation. We also offer secure cloud-based backup and disaster recovery solutions, ensuring data protection and business continuity. Our cloud storage services enable easy and secure file storage and collaboration.`  },
  {
    id: 'data-analytics',
    img: "/services/nft-creation.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/services/data-analytics.png",
    title: "Data Analytics",
    color: "#EF2A82",
    content:
      `Our comprehensive data analytics services provide businesses with valuable insights to drive informed decision-making. With our expertise, you can explore and visualize complex data, leveraging advanced analytics and predictive modeling to identify trends and optimize processes. We ensure data integrity and accessibility through efficient data warehousing and integration. Real-time analytics enable agile decision-making, while robust data governance and security practices protect your valuable information. Our customized solutions cater to your unique needs, delivering actionable insights through intuitive reporting and dashboards. `
  },
  {
    id: 'devops',
    img: "/services/nft-creation.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/devops.png",
    title: "DevOps",
    color: "#0088F0",
    content:
      `Techbrain Networks offers cutting-edge DevOps services to revolutionize software delivery and collaboration between development and operations teams. With our expertise, businesses can automate processes, achieve faster time-to-market, and ensure high-quality code with Continuous Integration and Continuous Deployment (CI/CD). Our Infrastructure as Code (IaC) practices provide consistent and scalable infrastructure management. Leverage containerization and orchestration technologies like Docker and Kubernetes for enhanced application scalability and portability. We prioritize security and compliance by implementing best practices and offer expert DevOps consulting and training to foster a DevOps culture within your organization.`
  },
  {
    id: "digital-marketing",
    img: "/services/digital-marketing.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/services/digital-marketing.webp",
    title: "Digital Marketing",
    color: "#8857FE",
    content:
      `With our expertise, you can elevate your online presence and drive meaningful results. Our services include search engine optimization (SEO) to boost your website's visibility, pay-per-click (PPC) advertising to maximize your online reach, and social media marketing to engage with your target audience. We also offer content marketing to create valuable and engaging content, email marketing to nurture customer relationships, and conversion rate optimization (CRO) to enhance website performance. With our analytics and reporting, you gain valuable insights into the effectiveness of your digital marketing campaigns.`
  },
  {
    id: 'e-commerce',
    img: "/services/e-com.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-pr.png",
    icon: "/ecom-2.webp",
    title: "E-Commerce",
    color: "#8F63DF",
    content:
      `Our services encompass every aspect of e-commerce, from platform development and shopping cart integration to payment gateway integration and inventory management. We optimize your online store for mobile devices, ensuring a seamless shopping experience for customers on all devices. Our integrated CRM solutions enable personalized customer interactions and enhanced loyalty. With advanced analytics and reporting, you gain valuable insights into your e-commerce performance and can make data-driven decisions to drive growth. Trust Techbrain Networks to empower your online store and maximize your e-commerce success.`
  },
  //   {
  // id: 1,
  //     img: "/services/nft-creation.png",
  //     dotL: "/dotL-o.png",
  //     dotR: "/dotR-y.png",
  //     icon: "/nft-2.webp",
  //     title: "NFT's Creation",
  //     color: "#410b87",
  //     content:
  //       "A non-fungible token (NFT) is a financial security consisting of digital data stored in a blockchain, a form of distributed ledger. The ownership of an NFT is recorded in the blockchain, and can be transferred by the owner, allowing NFTs to be sold and traded. NFTs can be created by anybody, and require few or no coding skills to create.",
  //   },

  {
    id: 'software-development',
    img: "/services/nft-creation.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-p.png",
    icon: "/soft-1.webp",
    title: "Software Development",
    color: "#60C9C5",
    content:
      `We specialize in custom software development, creating tailored solutions to meet your specific business needs. We excel in software integration, seamlessly connecting your applications with existing systems and APIs. Quality is paramount, as we follow rigorous testing and quality assurance practices throughout the development process. Our software maintenance and support services ensure that your applications remain updated, secure, and optimized for peak performance. With our agile development methodology, we prioritize collaboration, flexibility, and rapid delivery of top-notch software solutions. `
  },

  {
    id: 'ui-ux-design',
    img: "/services/ux-ui.webp",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/ux-ui-1.webp",
    title: "UI/UX Design",
    color: "#FF6B6B",
    content:
      "Techbrain Networks excels in delivering exceptional UI/UX design services to enhance user experiences. Through meticulous user research and analysis, we gain valuable insights into your target audience's preferences and needs. Our skilled designers create wireframes and interactive prototypes to validate design concepts before development. We craft visually appealing UI designs that align with your brand identity and create a seamless user interface. Our UX designers focus on optimizing navigation, interactions, and user goals to ensure a positive and intuitive user experience. With responsive design, we guarantee a consistent experience across various devices. Usability testing allows us to gather feedback and make iterative improvements based on real user insights."
  },
  {
    id: 'web3.0-apps',
    img: "/services/web-3.0.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/web-22.webp",
    title: "Web 3.0 Apps",
    color: "#6AA5FF",
    content:
      `Techbrain Networks specializes in developing cutting-edge Web 3.0 applications that leverage blockchain, decentralized technologies, and smart contracts. Our services include blockchain integration, smart contract development, and decentralized application (DApp) creation. We also explore tokenization and non-fungible tokens (NFTs) to enable new monetization models and revolutionize industries. With a focus on user-centric design and adherence to Web3 standards, our Web 3.0 apps provide seamless integration, enhanced security, and improved data privacy. Trust TechBrain Networks to bring your Web 3.0 ideas to life and unlock the full potential of decentralized technologies. Contact us today to embark on your Web 3.0 journey and stay ahead in the evolving digital landscape.`,
  }, {
    id: 'website-design',
    img: "/services/web-dev.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-b.png",
    icon: "/web-1.webp",
    title: "Website Design",
    color: "#d96a98",
    content:
      `Techbrain Networks excels in delivering stunning website designs that captivate and engage users. With our custom website design services, we create unique and tailored designs that reflect your brand identity. Our responsive design approach ensures seamless user experiences across devices. We prioritize user-centric design, focusing on intuitive navigation and clear calls to action. From visually appealing aesthetics to optimized content integration, our website designs are crafted to make a lasting impact. Trust TechBrain Networks to elevate your online presence with our exceptional website design services. Contact us today to transform your website into a captivating digital experience.`
  },
  {
    id: 'web-and-mobile-application',
    img: "/services/web-dev.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/services/mob-app-dev.webp",
    title: "Web & Mobile Application",
    color: "#1BDE9C",
    content:
      `Techbrain Networks is your trusted partner for developing high-quality web and mobile applications for both Android and iOS platforms. With our expertise in custom development, we create applications that cater to the unique features and guidelines of each operating system. Whether you need an Android app, an iOS app, or a cross-platform solution, we have the skills and experience to deliver exceptional results. Our team ensures that your application provides a seamless and optimized experience on both Android and iOS devices, maximizing your reach and user engagement. Contact us today to discuss your specific requirements and start building your Android and iOS applications with TechBrain Networks.`
  },
  {
    id: 'web-and-mobile-application',
    img: "/services/uiux2.png",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/services/uiux2.png",
    title: "Industrial Design",
    color: "#1BDE9C",
    content:
      `At TechBrain Network, we blend creativity and technology in our industrial design to create products that are both beautiful and functional. Our user-centric approach ensures intuitive and satisfying experiences, while maintaining a balance between aesthetics and performance. We emphasize sustainability through eco-friendly materials and processes. Ergonomics is crucial, ensuring comfort and safety for users. Our process includes research, concept development, 3D modeling, prototyping, and rigorous testing. By integrating smart technology and personalized design, we stay ahead of trends, shaping modern life effectively.`
  },
  {
    id: 'ui-ux-design',
    img: "/services/ux-ui.webp",
    dotL: "/dotL-o.png",
    dotR: "/dotR-y.png",
    icon: "/ux-ui-1.webp",
    title: "UI/UX Design",
    color: "#FF6B6B",
    content:
      "Techbrain Networks excels in delivering exceptional UI/UX design services to enhance user experiences. Through meticulous user research and analysis, we gain valuable insights into your target audience's preferences and needs. Our skilled designers create wireframes and interactive prototypes to validate design concepts before development. We craft visually appealing UI designs that align with your brand identity and create a seamless user interface. Our UX designers focus on optimizing navigation, interactions, and user goals to ensure a positive and intuitive user experience. With responsive design, we guarantee a consistent experience across various devices. Usability testing allows us to gather feedback and make iterative improvements based on real user insights."
  },
  {
    "id": "3d-printing",
    "img": "/services/3d.png",
    "dotL": "/dotL-o.png",
    "dotR": "/dotR-y.png",
    "icon": "/services/3d.png",
    "title": "3D Printing",
    "color": "#FF7F50",
    "content": "TechBrain Network excels in 3D printing, utilizing cutting-edge technologies to transform digital designs into tangible prototypes and products. We offer precise and efficient 3D printing services that cater to various industries, from automotive to healthcare. Our expertise extends from rapid prototyping to custom manufacturing, enabling iterative design improvements and cost-effective production. We ensure high-quality prints using a range of materials, tailored to meet specific project requirements. With a focus on innovation and client satisfaction, TechBrain Network leverages 3D printing to accelerate product development timelines and deliver solutions that exceed expectations."
},
{
  "id": "prototyping",
  "img": "/services/Prototyping.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Prototyping.png",
  "title": "Prototyping",
  "color": "#6AA5FF",
  "content": "At TechBrain Network, prototyping is a cornerstone of our design process, enabling us to quickly iterate and refine concepts into functional prototypes. We utilize advanced technologies and materials to create prototypes that accurately represent the final product's form, fit, and function. Our prototyping services span various industries, including consumer electronics, medical devices, and automotive. We emphasize rapid turnaround times and meticulous attention to detail, ensuring that prototypes meet rigorous testing standards and user expectations. By facilitating early-stage validation and feedback, TechBrain Network accelerates innovation and helps our clients bring high-quality products to market efficiently."
},
{
  "id": "product-lifecycle-management",
  "img": "/services/Lifecycle.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Lifecycle.png",
  "title": "Product Lifecycle Management",
  "color": "#f7be77",
  "content": "Product Lifecycle Management (PLM) at TechBrain Network covers the entire product lifecycle, from inception to disposal. Utilizing robust PLM strategies and software, we streamline workflows, enhance collaboration, and optimize decision-making across teams and stakeholders. Our approach integrates design, engineering, manufacturing, and supply chain management for seamless coordination and efficient execution. Prioritizing innovation and agility, we adapt our PLM strategies to meet evolving market demands and technological advancements. Leveraging data-driven insights and best practices, TechBrain Network helps clients achieve faster time-to-market, reduce costs, and maintain a competitive edge."
},
{
  "id": "market-and-feasibility-studies",
  "img": "/services/market-study.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/market-study.png",
  "title": "Market and Feasibility Studies",
  "color": "#8A2BE2",
  "content": "TechBrain Network specializes in comprehensive market and feasibility studies to assess the viability and success potential of new product ventures. Our expert analysts conduct thorough market research, analyzing industry trends, consumer behavior, and competitive landscapes. We evaluate market demand, pricing strategies, and regulatory considerations to provide actionable insights and recommendations. Our feasibility studies also assess technical feasibility, resource requirements, and financial projections to determine project viability and mitigate risks. With a data-driven approach, TechBrain Network equips clients with the intelligence needed to make informed decisions and optimize product development strategies."
},
{
  "id": "concept-development",
  "img": "/services/Sample 26.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Sample 26.png",
  "title": "Concept Development",
  "color": "#f7be77",
  "content": "Concept development at TechBrain Network is a creative and strategic process aimed at transforming ideas into innovative product concepts. We combine market insights, user research, and technical expertise to generate concepts that meet market needs and align with client goals. Our iterative approach involves brainstorming, sketching, and digital modeling to explore different design possibilities. We prioritize feasibility, scalability, and marketability during concept refinement, ensuring that final concepts are viable and compelling. By fostering collaboration and creativity, TechBrain Network accelerates the innovation cycle and empowers clients to launch successful products that resonate with their target audience."
},
{
  "id": "technology-research",
  "img": "/services/Lifecycle.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Lifecycle.png",
  "title": "Technology Research",
  "color": "#87CEEB",
  "content": "Technology research at TechBrain Network is integral to staying at the forefront of innovation. We conduct in-depth research and analysis of emerging technologies, trends, and advancements relevant to our clients' industries. Our expert researchers explore potential applications, benefits, and risks associated with new technologies, providing strategic insights for informed decision-making. We collaborate closely with clients to understand their technology needs and goals, tailoring our research efforts to support their innovation initiatives. By leveraging our extensive network and industry expertise, TechBrain Network helps clients adopt cutting-edge technologies that drive competitive advantage and future growth."
},
{
  "id": "prototype-development",
  "img": "/services/Prototyping.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Prototyping.png",
  "title": "Prototype Develop",
  "color": "#32CD32",
  "content": "Prototype development at TechBrain Network transforms concepts into functional prototypes using advanced technologies and materials. We emphasize rapid prototyping for quick iteration and validation, ensuring prototypes meet quality standards and user expectations. Our services accelerate development for consumer electronics, medical devices, and automotive components. TechBrain Network's expertise facilitates early-stage validation and refinement, leading to successful product launches."
},
{
  "id": "innovation-strategy",
  "img": "/services/innovation-strategy.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/innovation-strategy.png",
  "title": "Innovation Strategy",
  "color": "#FF6347",
  "content": "At TechBrain Network, innovation strategy drives sustainable growth and competitive advantage. We collaborate with organizations to create tailored strategies based on industry and market dynamics. Our approach includes identifying emerging trends and market opportunities through research and analysis, and facilitating ideation sessions to foster innovation. Leveraging our expertise and global network, we help clients navigate disruptive technologies and market shifts. TechBrain Network's strategies enable organizations to optimize resources, mitigate risks, and accelerate the development of breakthrough products and solutions."
},
{
  "id": "embedded-systems",
  "img": "/services/pcb.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/pcb.png",
  "title": "Embedded Systems",
  "color": "#6A5ACD",
  "content": "Embedded systems are a cornerstone of TechBrain Network's expertise, encompassing the design, development, and integration of software and hardware solutions for specialized applications. We specialize in creating embedded systems for diverse industries such as automotive, aerospace, healthcare, and IoT. Our solutions range from microcontroller-based systems to complex real-time operating systems (RTOS) and firmware development. We prioritize performance, reliability, and efficiency in our designs, ensuring seamless integration and optimal functionality. With a focus on innovation and precision engineering, TechBrain Network empowers clients to leverage embedded systems technology for enhanced product capabilities, operational efficiency, and market competitiveness."
},
{
  "id": "iot-devices",
  "img": "/services/IoT-devices.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/IoT-devices.png",
  "title": "IoT Devices",
  "color": "#FFA07A",
  "content": "TechBrain Network specializes in designing and developing IoT (Internet of Things) devices that connect physical objects to the digital world, enabling data-driven insights and automation. We offer end-to-end IoT solutions from concept and prototype development to deployment and maintenance. Our expertise spans sensor integration, connectivity protocols, cloud platforms, and data analytics, tailored to various industry applications such as smart home automation, industrial IoT, healthcare monitoring, and environmental sensing. We emphasize scalability, security, and interoperability in our IoT designs to ensure robust performance and user safety. By harnessing the power of IoT, TechBrain Network helps clients innovate, optimize operations, and create transformative experiences for their customers."
},
{
  "id": "pcb-design-and-fabrication",
  "img": "/services/pcb-1.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/pcb-1.png",
  "title": "PCB Design & Fabrication",
  "color": "#f7be77",
  "content": "TechBrain Network excels in PCB (Printed Circuit Board) design and fabrication, providing comprehensive services from initial concept to final production. We leverage advanced CAD tools and simulation software to create optimized PCB layouts that meet client specifications and performance requirements. Our team ensures high-quality fabrication using state-of-the-art manufacturing processes and materials, adhering to industry standards and best practices. We support diverse applications across industries such as consumer electronics, automotive, telecommunications, and medical devices. Whether for prototyping or large-scale production, TechBrain Network delivers reliable PCB solutions that enable innovation, efficiency, and product reliability."
},
{
  "id": "prototyping-and-testing",
  "img": "/services/Prototyping.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Prototyping.png",
  "title": "Prototyping & Testing",
  "color": "#6AA5FF",
  "content": "Prototyping and testing are integral to TechBrain Network's product development process, ensuring robust and reliable solutions. We excel in rapid prototyping, using advanced technologies to quickly iterate and refine designs. Our testing procedures encompass rigorous evaluations to validate performance, durability, and safety. We employ industry-standard testing methodologies and simulation tools to simulate real-world conditions and identify potential issues early in the development cycle. From concept validation to final product certification, TechBrain Network's prototyping and testing services support innovation and mitigate risks, ensuring products meet stringent quality standards and exceed customer expectations."
},
{
  "id": "custom-hardware-solutions",
  "img": "/services/Custom.png",
  "dotL": "/dotL-o.png",
  "dotR": "/dotR-y.png",
  "icon": "/services/Custom.png",
  "title": "Custom Hardware Solutions",
  "color": "#800080",
  "content": "TechBrain Network develops custom hardware solutions tailored to unique client needs and industry-specific applications. Offering end-to-end services from concept design to production and deployment, our expertise includes embedded systems, PCB design, sensor integration, and firmware development. We emphasize innovation and quality, creating bespoke hardware that optimizes performance, functionality, and reliability. Serving IoT, industrial automation, and specialized equipment sectors, we ensure seamless integration and efficient operation. TechBrain Network empowers clients to innovate, differentiate their products, and achieve business objectives with cutting-edge technology."
}
];

export const servicesData = [
  {
    title: "Cloud Services",
    id: 'cloud-services',
    seoTitle: 'Cloud Services',
    seoDesc: 'Cloud Services',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `At Techbrain Networks, we understand the importance of harnessing the potential of cloud computing. Our comprehensive suite of cloud services enables businesses to scale, innovate, and stay ahead in today's rapidly evolving digital landscape. With our reliable and secure cloud solutions, we empower organizations to optimize their operations, enhance collaboration, and drive business growth.`,

    sections: [
      {
        img: '/cloud.png',
        title: 'Unlocking the Potential of Cloud Computing',
        content: [
          `Scalability and Flexibility:/n Seamlessly scale your IT resources up or down based on your business needs. Our cloud services provide the flexibility to accommodate growth, ensuring your infrastructure can adapt to changing demands.`,
          `Cost Efficiency:/n Say goodbye to upfront hardware investments and maintenance costs. With our cloud solutions, you can leverage a pay-as-you-go model, paying only for the resources you consume, resulting in significant cost savings.`,
          `Enhanced Security:/n Safeguard your data with our robust security measures. Our cloud services employ advanced encryption, multi-factor authentication, and regular backups to protect your valuable information from threats and ensure business continuity.`,
          `Collaboration and Accessibility:/n Empower your team to work together seamlessly from anywhere, at any time. With our cloud services, you can access and collaborate on files, documents, and applications in real-time, promoting productivity and fostering innovation.`
        ]
      },
      {
        img: '/Saly-2.png',
        title: 'Tailored Cloud Solutions for Every Business',
        content: [
          `Infrastructure as a Service (IaaS):/n Leverage our scalable and reliable infrastructure to host your applications and data. Our IaaS solutions provide a flexible and cost-effective approach to managing your IT infrastructure.`,
          `Platform as a Service (PaaS):/n Focus on developing and deploying applications without the hassle of managing the underlying infrastructure. Our PaaS offerings enable you to streamline development processes and accelerate time to market.`,
          `Software as a Service (SaaS):/n Access our suite of software applications hosted in the cloud, eliminating the need for installation or maintenance. Our SaaS solutions provide hassle-free access to industry-leading software tailored to your business needs.`
        ]
      },
      {
        img: '/hero-img.png',
        title: 'Partnering for Cloud Success',
        content: [
          `Expertise and Experience:/n With years of experience in cloud computing, our team of certified professionals possesses the expertise to design, implement, and manage cloud solutions tailored to your specific requirements.`,
          `Reliability and Performance:/n Our robust infrastructure and redundant systems ensure high availability and optimal performance for your critical applications and data, minimizing downtime and maximizing productivity.`,
          `Seamless Migration:/n Our migration experts will guide you through a smooth transition to the cloud, minimizing disruption to your operations and ensuring a seamless experience.`
        ]
      },
    ]
  },
  {
    title: "Data Analytics",
    id: 'data-analytics',
    seoTitle: 'Data Analytics',
    seoDesc: 'Data Analytics',
    icon: <DataUsageIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In today's data-driven world, making informed decisions is crucial for business success. At Techbrain Networks, we offer comprehensive data analytics services that enable organizations to extract valuable insights from their data. By leveraging advanced analytics techniques and cutting-edge technologies, we empower businesses to unlock hidden patterns, optimize operations, and drive growth.`,
    sections: [
      {
        img: '/Saly-10.png',
        title: 'Turning Data into Actionable Insights',
        content: [
          `Improved Decision-Making:/n Harness the power of data analytics to make data-driven decisions. Our services provide the tools and techniques to analyze vast amounts of data, identify trends, and gain actionable insights, enabling you to make informed choices that drive business outcomes.`,
          `Enhanced Operational Efficiency:/n Streamline your business processes and identify areas for improvement with data analytics. By analyzing operational data, you can identify bottlenecks, optimize workflows, and increase efficiency, ultimately reducing costs and enhancing productivity.`,
          `Customer Insights and Personalization:/n Understand your customers better and deliver personalized experiences. Data analytics allows you to analyze customer behavior, preferences, and feedback, enabling targeted marketing campaigns, personalized recommendations, and improved customer satisfaction.`,
          `Competitive Advantage:/n Gain a competitive edge by leveraging data analytics. By analyzing market trends, competitors, and customer sentiments, you can identify new opportunities, optimize pricing strategies, and stay ahead of the competition.`

        ]
      },
      {
        img: '/devops.png',
        title: 'Empowering Insights through Advanced Analytics',
        content: [
          `Data Exploration and Visualization:/n Uncover hidden patterns and trends through interactive data visualizations. Our data analytics services provide intuitive visualizations and dashboards that make it easy to explore and understand your data.`,
          `Descriptive Analytics:/n Gain insights into past performance and historical data through descriptive analytics. We help you analyze data to understand what happened, why it happened, and identify patterns and trends.`,
          `Predictive Analytics:/n Forecast future outcomes and make proactive decisions with predictive analytics. Our experts utilize statistical modeling, machine learning, and data mining techniques to predict trends, customer behavior, and business outcomes.`,
          `Prescriptive Analytics:/n Optimize decision-making with prescriptive analytics. We leverage advanced algorithms and optimization techniques to provide actionable recommendations and insights, enabling you to make optimal choices for your business.`
        ]
      },
      {
        img: '/services/data-analytics.png',
        title: 'Partnering for Data-Driven Success',
        content: [
          `Expertise and Experience:/n Our team of data scientists and analytics experts brings extensive experience and expertise in handling complex data analytics projects across various industries. We have a deep understanding of diverse data sets and the tools and technologies required to extract meaningful insights.`,
          `Scalable Infrastructure:/n We provide a scalable infrastructure capable of handling large volumes of data. Our robust analytics platforms ensure reliable and efficient processing of data, enabling timely insights and analysis.`,
          `Data Security and Privacy:/n We prioritize the security and privacy of your data. Our data analytics services adhere to industry best practices and compliance standards, ensuring your data is protected throughout the analytics process.`
        ]
      },
    ]
  },
  
  {
    title: "DevOps",
    id: 'devops',
    seoTitle: 'DevOps',
    seoDesc: 'DevOps',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In today's fast-paced digital landscape, organizations strive to deliver software quickly and efficiently while maintaining high-quality standards. At Techbrain Networks, we offer comprehensive DevOps services that bridge the gap between development and operations, enabling businesses to achieve faster time-to-market, enhanced collaboration, and improved software reliability.`,
    sections: [
      {
        img: '/devops1.png',
        title: 'Transforming Software Delivery',
        content: [
          `Continuous Integration and Deployment:/n DevOps practices enable continuous integration and deployment, allowing for the rapid and automated delivery of software updates. This results in reduced time-to-market, faster feedback cycles, and improved overall software quality.`,
          `Collaboration and Communication:/n DevOps fosters collaboration and communication between development, operations, and other stakeholders involved in the software delivery process. By breaking down silos and encouraging cross-functional teams, organizations can streamline workflows, reduce errors, and enhance overall efficiency.`,
          `Increased Reliability and Stability:/n By automating build, testing, and deployment processes, DevOps minimizes human error and ensures consistent and reliable software releases. Continuous monitoring and feedback loops enable quick identification and resolution of issues, resulting in increased system stability.`,
          `Scalability and Flexibility:/n DevOps practices and tools enable organizations to scale their infrastructure and applications efficiently. By leveraging cloud computing and automation, businesses can easily adapt to changing demands, handle increased workloads, and optimize resource utilization.`
        ]
      },
      {
        img: '/devops2.png',
        title: 'Streamlining Software Delivery',
        content: [
          `Continuous Integration and Deployment (CI/CD):/n Implement robust CI/CD pipelines to automate the building, testing, and deployment of software applications. Our DevOps experts design and configure CI/CD workflows tailored to your specific needs, ensuring efficient and reliable software delivery.`,
          `Infrastructure Automation:/n Streamline infrastructure management through infrastructure as code (IaC) techniques. Our DevOps services help you automate the provisioning, configuration, and orchestration of your infrastructure, enabling faster deployments, improved scalability, and reduced manual errors.`,
          `Configuration Management:/n Ensure consistency and reliability across your infrastructure with effective configuration management. We assist in implementing configuration management tools and processes, enabling easy tracking, versioning, and deployment of configuration changes.`,
          `Continuous Monitoring and Feedback:/n Establish robust monitoring and feedback mechanisms to proactively detect issues and gather valuable insights. Our DevOps solutions integrate monitoring tools, log management, and analytics to provide real-time visibility into application performance and infrastructure health.`
        ]
      },
      {
        img: '/devops3.png',
        title: 'Partnering for Agile Software Delivery',
        content: [
          `Expertise and Experience:/n Our team of DevOps engineers possesses deep expertise in implementing DevOps practices and leveraging industry-leading tools. With extensive experience across various domains, we understand the unique challenges organizations face and provide tailored solutions for maximum impact.`,
          `Automation and Toolchain Integration:/n We leverage a wide array of automation tools and integrate them seamlessly into your software delivery pipelines. This ensures efficient and repeatable processes, minimizing manual effort and maximizing productivity.`,
          `Collaboration and Cultural Transformation:/n We emphasize the importance of fostering a collaborative and agile culture within your organization. Our DevOps services include training, coaching, and organizational change management to promote cross-functional collaboration and adoption of DevOps practices.`
        ]
      },
    ]
  },
  
  {
    title: "Digital Marketing",
    id: "digital-marketing",
    seoTitle: 'Digital Marketing',
    seoDesc: 'Digital Marketing',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In today's digital era, businesses must have a strong online presence to effectively reach and engage their target audience. At Techbrain Networks, we offer comprehensive digital marketing services designed to help organizations expand their online reach, drive brand awareness, and achieve measurable business growth. With our expertise in digital marketing strategies and cutting-edge tools, we empower businesses to thrive in the digital landscape.`,
     sections: [
      {
        img: '/digital-market-3.png',
        title: 'Driving Success in the Digital World',
        content: [
          `Increased Brand Visibility:/n Digital marketing enables businesses to reach a wider audience across various online platforms. By implementing effective SEO (Search Engine Optimization) strategies, social media marketing, and content marketing, organizations can enhance their brand visibility and attract more potential customers.`,
          `Targeted Audience Reach:/n With digital marketing, businesses can precisely target their audience based on demographics, interests, and online behaviors. This targeted approach ensures that marketing efforts are focused on the most relevant and interested prospects, leading to higher conversion rates and improved ROI.`,
          `Enhanced Customer Engagement:/n Digital marketing allows businesses to engage with their customers in real-time through various channels such as social media, email marketing, and personalized website experiences. This direct interaction fosters customer loyalty, encourages brand advocacy, and drives customer satisfaction.`,
          `Measurable Results and Data-Driven Insights:/n Digital marketing provides comprehensive analytics and tracking capabilities, allowing businesses to measure the performance of their marketing campaigns accurately. This data-driven approach provides valuable insights into customer behavior, campaign effectiveness, and areas for improvement, enabling businesses to refine their strategies and optimize their marketing efforts.`,

        ]
      },
      {
        img: '/digital-market-4.png',
        title: 'Tailored Strategies for Online Success',
        content: [
          `Search Engine Optimization (SEO):/n Enhance your website's visibility and organic search rankings with our SEO strategies. We conduct thorough keyword research, optimize website content, and implement technical optimizations to improve search engine rankings and drive organic traffic.`,
          `Social Media Marketing:/n Leverage the power of social media platforms to connect with your target audience, build brand awareness, and drive engagement. Our social media marketing services encompass strategy development, content creation, community management, and paid advertising campaigns.`,
          `Pay-Per-Click (PPC) Advertising:/n Maximize your online visibility and generate immediate results with PPC advertising campaigns. We create targeted ads, manage bidding strategies, and continuously optimize campaigns to drive qualified traffic and increase conversions.`,
          `Content Marketing:/n Engage your audience and establish thought leadership through compelling content. Our content marketing services include content strategy, creation, distribution, and optimization, ensuring your brand resonates with your target audience across various channels.`

        ]
      },
      {
        img: '/services/digital-marketing.png',
        title: 'Partnering for Digital Success',
        content: [
          `Expertise and Experience:/n Our digital marketing team consists of experienced professionals who stay updated with the latest trends and best practices in the industry. With a deep understanding of diverse digital marketing channels, we tailor strategies to meet your specific business goals.`,
          `Data-Driven Approach:/n We prioritize data and analytics to drive continuous improvement. Our digital marketing services leverage advanced analytics tools to measure campaign performance, track conversions, and gain actionable insights for optimization.`,
          `Creative Excellence:/n We combine strategic thinking with creative excellence to deliver compelling digital marketing campaigns. Our team develops engaging content, eye-catching visuals, and impactful messaging that resonate with your target audience, driving engagement and brand loyalty.`

        ]
      },
    ]
  },
  
  {
    title: "E-Commerce",
    id: 'e-commerce',
    seoTitle: 'E-Commerce',
    seoDesc: 'E-Commerce',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In today's digital era, businesses must have a strong online presence to effectively reach and engage their target audience. At Techbrain Networks, we offer comprehensive digital marketing services designed to help organizations expand their online reach, drive brand awareness, and achieve measurable business growth. With our expertise in digital marketing strategies and cutting-edge tools, we empower businesses to thrive in the digital landscape.`,
     sections: [
      {
        img: '/ecom3.png',
        title: 'Unleashing the Power of Online Selling',
        content: [
          `Global Reach and Market Expansion:/n E-commerce enables businesses to overcome geographical boundaries and reach customers worldwide. By establishing an online store, organizations can tap into new markets, expand their customer base, and drive growth on a global scale.`,
          `Enhanced Customer Convenience:/n E-commerce provides customers with the convenience of shopping from anywhere, at any time. With features like 24/7 availability, personalized recommendations, and secure payment options, businesses can deliver seamless and satisfying shopping experiences, fostering customer loyalty.`,
          `Cost Efficiency and Scalability:/n Compared to traditional brick-and-mortar stores, e-commerce offers cost advantages in terms of lower overhead costs, reduced inventory management, and streamlined operations. Additionally, e-commerce platforms can scale effortlessly to accommodate growing business demands.`,
          `Data-Driven Insights:/n E-commerce provides businesses with valuable data and analytics to understand customer behavior, preferences, and buying patterns. This data-driven approach enables organizations to make informed decisions, optimize marketing strategies, and tailor personalized experiences for their customers.`
        ]
      },
      {
        img: '/ecom2.png',
        title: 'Tailored Solutions for Online Success',
        content: [
          `E-Commerce Platform Development:/n We specialize in developing customized e-commerce platforms that align with your business objectives and cater to your specific requirements. Our solutions integrate robust features for product management, secure payment processing, inventory tracking, and seamless user experiences.`,
          `Mobile Commerce (M-Commerce):/n With the rise of mobile devices, we optimize your e-commerce store for mobile experiences. Our M-commerce solutions ensure your online store is responsive, intuitive, and delivers a consistent shopping experience across devices, empowering customers to shop on the go.`,
          `Payment Gateway Integration:/n We integrate secure payment gateways into your e-commerce platform, providing customers with multiple payment options while ensuring the highest level of transaction security. Our expertise covers popular payment gateways, enabling smooth and secure online transactions.`,
          `Conversion Rate Optimization (CRO):/n We focus on optimizing your e-commerce website to increase conversions and drive sales. Our CRO strategies involve analyzing user behavior, conducting A/B testing, optimizing product pages, and improving the overall user experience to maximize your e-commerce revenue.`
        ]
      },
      {
        img: '/ecom1.png',
        title: 'Partnering for Online Retail Success',
        content: [
          `Experience and Expertise:/n Our team has extensive experience in developing and implementing e-commerce solutions for diverse industries. We stay updated with the latest e-commerce trends, technologies, and best practices to deliver cutting-edge solutions tailored to your business needs.`,
          `User-Centric Approach:/n We prioritize delivering exceptional user experiences to drive customer satisfaction and loyalty. Our e-commerce solutions focus on intuitive navigation, personalized product recommendations, smooth checkout processes, and responsive designs that cater to your target audience.`,
          `Scalability and Security:/n We understand the importance of scalability and security in e-commerce. Our solutions are built on robust platforms that can handle high volumes of traffic and transactions. We implement industry-leading security measures to protect customer data and ensure secure online transactions.`
        ]
      },
    ]
  },
  
  {
    id: 'software-development',
    title: "Software Development",
    seoTitle: 'Software Development',
    seoDesc: 'Software Development',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In today's technology-driven world, software development plays a crucial role in shaping businesses and driving innovation. At Techbrain Networks, we specialize in providing comprehensive software development services that help organizations transform their ideas into robust and scalable software solutions. With our expertise in cutting-edge technologies, agile methodologies, and industry best practices, we empower businesses to stay ahead of the competition and deliver impactful software products.`,
     sections: [
      {
        img: '/sd1.png',
        title: 'Enabling Digital Transformation',
        content: [
          `Customized Solutions:/n Software development allows businesses to tailor solutions to their specific needs and requirements. Custom software provides a competitive advantage by addressing unique challenges, streamlining processes, and enhancing overall productivity.`,
          `Scalability and Flexibility:/n Developing scalable software ensures that businesses can adapt and grow without constraints. Scalable solutions can handle increased workloads, expanding user bases, and evolving business needs, enabling organizations to stay agile and responsive in a dynamic market.`,
          `Process Automation:/n Software development enables process automation, reducing manual effort, eliminating errors, and improving operational efficiency. Automation of repetitive tasks and workflows frees up valuable resources, allowing employees to focus on high-value activities.`,
          `Integration and Collaboration:/n Software development facilitates seamless integration with existing systems and platforms, improving data flow and collaboration across departments. Integrated software solutions enable real-time information sharing, efficient communication, and streamlined workflows, leading to enhanced productivity and better decision-making.`
        ]
      },
      {
        img: '/sd2.png',
        title: 'Tailored Solutions for Innovative Growth',
        content: [
          `Custom Software Development:/n We specialize in developing customized software solutions tailored to your unique business needs. Our experienced software development team follows agile methodologies, utilizing the latest technologies and best practices to deliver high-quality, scalable, and secure software applications.`,
          `Web Application Development:/n We design and develop robust web applications that leverage the power of the internet to connect businesses with their customers and stakeholders. Our web applications are user-friendly, responsive, and feature-rich, providing seamless experiences across various devices.`,
          `Mobile App Development:/n We create engaging and intuitive mobile applications for iOS and Android platforms. Our mobile app development services cover the entire development lifecycle, from concept ideation and UI/UX design to coding, testing, and deployment, ensuring that your app meets the highest quality standards.`,
          `Software Testing and Quality Assurance:/n We offer comprehensive software testing and quality assurance services to ensure that your software solutions are reliable, secure, and perform optimally. Our testing processes encompass functional testing, performance testing, security testing, and usability testing to deliver a seamless user experience.`
        ]
      },
      {
        img: '/sd3.png',
        title: 'Partnering for Technological Excellence',
        content: [
          `Technical Expertise:/n Our software development team consists of highly skilled professionals with extensive experience in diverse technologies and frameworks. We stay updated with the latest industry trends and continuously enhance our skills to deliver cutting-edge software solutions.`,
          `Agile Development Methodologies:/n We follow agile development methodologies to ensure flexibility, transparency, and collaboration throughout the software development lifecycle. Our iterative approach enables quick feedback, adaptive planning, and rapid delivery of high-quality software solutions.`,
          `Collaborative Approach:/n We believe in building strong partnerships with our clients. Our collaborative approach involves close communication, regular progress updates, and active involvement in the development process, ensuring that your vision is translated into a successful software product.`
        ]
      },
    ]
  },
  
  {
    id: 'web3.0-apps',
    title: "Web 3.0 Apps",
    seoTitle: 'Web 3.0 Apps',
    seoDesc: 'Web 3.0 Apps',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `Web 3.0 represents the next evolution of the internet, enabling a decentralized and user-centric online ecosystem. At Techbrain Networks, we specialize in developing cutting-edge Web 3.0 applications that leverage blockchain technology, decentralized networks, and smart contracts to provide secure, transparent, and innovative solutions. With our expertise in Web 3.0 technologies, we help businesses embrace the future of the internet and unlock new possibilities for their digital ventures.`,
    sections: [
      {
        img: '/web3.01.png',
        title: 'Empowering Decentralized Experiences',
        content: [
          `Enhanced Security and Privacy:/n Web 3.0 applications leverage blockchain technology to enhance security and privacy. By decentralizing data storage and utilizing cryptographic principles, Web 3.0 apps offer robust security measures, protecting user data from unauthorized access and ensuring transparency in data transactions.`,
          `User Ownership and Control:/n Web 3.0 apps empower users by giving them ownership and control over their data and digital assets. Through decentralized networks and smart contracts, individuals can interact directly with applications, maintain control over their personal information, and participate in a more democratic and equitable online environment.`,
          `Interoperability and Seamless Integration:/n Web 3.0 applications promote interoperability by enabling seamless integration between different platforms and systems. Through open protocols and standardized data formats, Web 3.0 apps allow for efficient data exchange, collaboration, and the creation of innovative cross-platform experiences.`,
          `Trust and Transparency:/n Web 3.0 apps provide transparency through blockchain's immutable and auditable nature. Smart contracts enable automated and verifiable transactions, reducing the need for intermediaries and fostering trust between participants. This transparency builds credibility, mitigates fraud, and enhances user trust in online interactions.`
        ]
      },
      {
        img: '/web3.02.png',
        title: 'Building the Future of Decentralized Applications',
        content: [
          `Decentralized Application (DApp) Development:/n We specialize in developing decentralized applications (DApps) that leverage blockchain technology and smart contracts. Our team designs and builds secure, scalable, and user-friendly DApps that offer innovative solutions across various industries, including finance, supply chain, gaming, and more.`,
          `Blockchain Integration:/n We provide expertise in integrating blockchain technology into existing web applications or developing new applications with blockchain capabilities. Our team identifies the most suitable blockchain platforms and protocols for your specific requirements, ensuring seamless integration and optimal performance.`,
          `Smart Contract Development:/n Smart contracts are the backbone of Web 3.0 applications. We offer smart contract development services using blockchain platforms such as Ethereum, ensuring the secure execution of digital agreements and enabling automation of complex business processes.`,
          `Decentralized Storage and Identity Solutions:/n We leverage decentralized storage platforms and identity protocols to enhance security, privacy, and user control within Web 3.0 applications. Our solutions integrate decentralized storage networks and identity management frameworks to protect data and enable seamless user experiences.`
        ]
      },
      {
        img: '/web3.03.png',
        title: 'Partnering for Decentralized Innovation',
        content: [
          `Expertise in Web 3.0 Technologies:/n Our team possesses in-depth knowledge and expertise in Web 3.0 technologies, including blockchain, decentralized networks, smart contracts, and identity solutions. We stay at the forefront of emerging trends to deliver innovative and future-proof Web 3.0 applications.`,
          `Security and Privacy Focus:/n We prioritize the security and privacy of your Web 3.0 applications. Our development practices adhere to industry best practices and security standards, ensuring robust protection for user data and secure execution of smart contracts.`,
          `Collaborative Approach:/n We believe in collaborative partnerships with our clients. Our team works closely with you to understand your business requirements, provide strategic guidance, and deliver tailor-made Web 3.0 solutions that align with your vision and goals.`,
        ]
      },
    ]
  },

  
  {
    id: 'website-design',
    title: "Website Design",
    seoTitle: 'Website Design',
    seoDesc: 'Website Design',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc:`In the digital era, a well-designed website is essential for businesses to make a strong online presence and engage with their target audience. At Techbrain Networks, we specialize in website design services that blend creativity, functionality, and user-centric experiences. With our expertise in user interface (UI) design, user experience (UX) optimization, and the latest design trends, we create visually appealing and intuitive websites that captivate visitors and drive meaningful interactions.`,
     sections: [
      {
        img: '/wd1.png',
        title: 'Elevating Your Online Brand',
        content: [
          `First Impressions and Brand Identity:/n A professionally designed website helps establish a positive first impression and communicates your brand's identity effectively. Through strategic use of colors, typography, imagery, and layout, we ensure that your website reflects your brand's values and resonates with your target audience.`,
          `User Experience and Engagement:/n A well-designed website focuses on enhancing user experience and engagement. Intuitive navigation, clear call-to-action buttons, and seamless interactions create a smooth and enjoyable browsing experience, encouraging visitors to explore your content, products, or services further.`,
          `Responsive Design and Mobile Optimization:/n With the increasing use of mobile devices, responsive design is crucial. We prioritize mobile optimization to ensure your website looks and functions flawlessly across various screen sizes, providing a consistent user experience and maximizing accessibility.`,
          `Search Engine Optimization (SEO) Compatibility:/n Professional website design incorporates SEO best practices to improve search engine rankings and increase organic traffic. We optimize website structure, metadata, page loading speed, and other elements to enhance your website's visibility and reach online.`
        ]
      },
      {
        img: '/wd2.png',
        title: 'Tailored Designs for Digital Success',
        content: [
          `UI/UX Design:/n Our team of designers creates visually striking and user-friendly interfaces that captivate visitors and deliver seamless experiences. We conduct in-depth user research, wireframing, and prototyping to ensure intuitive navigation, engaging interactions, and a cohesive visual hierarchy throughout your website.`,
          `Custom Website Design:/n We offer custom website design services tailored to your specific business needs. Whether you require an informational website, e-commerce platform, portfolio site, or any other online presence, we combine your vision with our expertise to design a unique and impactful website that represents your brand.`,
          `Responsive Design and Mobile Optimization:/n We prioritize responsive design, ensuring your website is visually appealing and functional across various devices. Our team optimizes layouts, images, and content to adapt seamlessly to different screen sizes, delivering an excellent user experience on desktops, tablets, and smartphones.`,
          `Website Redesign and Updates:/n If you already have a website that needs a fresh look or improved functionality, we offer website redesign services. Our team performs a thorough evaluation of your existing site, identifies areas for improvement, and implements design updates and enhancements to revitalize your online presence.`
        ]
      },
      {
        img: '/wd3.png',
        title: 'Partnering for Digital Excellence',
        content: [
          `Design Expertise and Creativity:/n Our team of experienced designers possesses a deep understanding of design principles, current trends, and user behavior. We combine creativity with technical expertise to deliver visually stunning and highly functional websites that leave a lasting impact.`,
          `User-Centric Approach:/n We prioritize user experience in every aspect of website design. Through user research, usability testing, and data analysis, we ensure that your website caters to the needs, expectations, and preferences of your target audience, maximizing engagement and conversions.`,
          `Collaborative Partnership:/n We believe in building strong partnerships with our clients. Our team works closely with you, involving you in the design process, incorporating your feedback, and ensuring that the final website aligns with your brand identity and business objectives.`
        ]
      },
    ]
  },
  
  {
    id: 'web-and-mobile-application',
    title: "Web & Mobile Application",
    seoTitle: 'Web & Mobile Application',
    seoDesc: 'Web & Mobile Application',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In the digital era, a well-designed website is essential for businesses to make a strong online presence and engage with their target audience. At Techbrain Networks, we specialize in website design services that blend creativity, functionality, and user-centric experiences. With our expertise in user interface (UI) design, user experience (UX) optimization, and the latest design trends, we create visually appealing and intuitive websites that captivate visitors and drive meaningful interactions.`,
     sections: [
      {
        img: '/wmd1.png',
        title: 'Empowering Connectivity and Accessibility',
        content: [
          `Enhanced User Engagement:/n Web and mobile applications provide a platform for businesses to connect and engage with their target audience. Through intuitive interfaces, personalized experiences, and interactive features, applications foster deeper user engagement, encourage loyalty, and drive customer satisfaction.`,
          `Cross-Platform Reach:/n Developing both web and mobile applications allows businesses to reach a wider audience. Web applications ensure compatibility across different browsers and operating systems, while mobile apps cater to the increasing mobile usage, providing a native and optimized experience on smartphones and tablets.`,
          `Streamlined Processes and Efficiency:/n Applications streamline business processes, automate repetitive tasks, and enhance operational efficiency. By leveraging data integration, real-time updates, and process automation, businesses can save time, reduce errors, and optimize productivity.`,
          `Accessible Anytime, Anywhere:/n Web and mobile applications enable users to access information and services anytime, anywhere, as long as they have an internet connection. This accessibility enhances convenience, allowing users to engage with businesses, make purchases, or access resources on their own terms.`
         ]
      },
      {
        img: '/wmd3.png',
        title: 'Tailored Solutions for Digital Success',
        content: [
          `Custom Application Development:/n We specialize in developing custom web and mobile applications tailored to your specific business requirements. Our experienced team follows agile development methodologies, utilizing the latest frameworks and technologies to deliver scalable, secure, and high-performing applications.`,
          `UI/UX Design and Optimization:/n We focus on creating intuitive and visually appealing interfaces that enhance user experiences. Our UI/UX design experts conduct user research, wireframing, and prototyping to ensure seamless navigation, engaging interactions, and a visually pleasing interface across web and mobile platforms.`,
          `Cross-Platform Development:/n We offer cross-platform application development, ensuring compatibility and consistent user experiences across different devices and operating systems. By utilizing frameworks such as React Native, Flutter, or Xamarin, we develop applications that leverage a single codebase for efficient development and maintenance.`,
          `Application Integration and API Development:/n We provide expertise in integrating applications with existing systems, databases, or third-party services, ensuring seamless data flow and functionality. Additionally, we offer API development services to create robust and secure APIs that enable data exchange and integration between applications.`
        ]
      },
      {
        img: '/wmd2.png',
        title: 'Partnering for Digital Excellence',
        content: [
          `Technical Expertise:/n Our development team comprises experienced professionals with expertise in a wide range of web and mobile technologies, frameworks, and platforms. We stay updated with the latest industry trends, ensuring that we deliver innovative and future-proof applications.`,
          `User-Centric Approach:/n We prioritize user experience in every aspect of application development. Our team conducts user research, usability testing, and continuous feedback loops to ensure that your application meets the needs, preferences, and expectations of your target audience.`,
          `Agile Development Methodologies:/n We follow agile development methodologies to ensure flexibility, collaboration, and rapid delivery of high-quality applications. Our iterative approach allows for quick feedback, adaptability to changing requirements, and continuous improvement throughout the development process.`
        ]
      },
    ]
  },

  
  {
    id: 'ui-ux-design',
    title: "UI/UX Design",
    seoTitle: 'dddd',
    seoDesc: 'dddd',
    icon: <CloudQueueIcon color='inherit' sx={{ fontSize: ['1rem', '1.2rem', '1.4rem'] }} />,
    desc: `In the digital age, creating exceptional user experiences is vital for businesses to stand out and succeed. At Techbrain Networks, we specialize in UI/UX design services that blend creativity, functionality, and user-centricity. With our expertise in user research, information architecture, and visual design, we create intuitive and visually appealing interfaces that captivate users and drive meaningful interactions.`,
   sections: [
      {
        img: '/services/uiux1.png',
        title: 'Elevating User Engagement and Satisfaction',
        content: [
          `Enhanced User Experience:/n Professional UI/UX design focuses on creating seamless and enjoyable user experiences. By understanding user behaviors, preferences, and pain points, we optimize the user journey, simplify navigation, and streamline interactions, resulting in increased user satisfaction and engagement.`,
          `Improved Usability and Accessibility:/n UI/UX design ensures that your digital products are easy to use and accessible to a wide range of users. Through intuitive layouts, clear information hierarchy, and inclusive design practices, we enhance usability, minimize learning curves, and cater to diverse user needs.`,
          `Brand Differentiation and Recognition:/n Well-crafted UI/UX design helps establish a unique brand identity and fosters recognition among users. By incorporating your brand elements, such as colors, typography, and visual styles, we create cohesive and visually appealing interfaces that reflect your brand personality and values.`,
          `Increased Conversions and Customer Loyalty:/n Intuitive and user-friendly interfaces drive conversions and customer loyalty. By focusing on effective call-to-action placement, seamless checkout processes, and personalized experiences, we optimize user flows and encourage users to take desired actions, leading to increased conversions and repeat business.`
        ]
      },
      {
        img: '/services/uiux2.png',
        title: 'Tailored Designs for Optimal User Experiences',
        content: [
          `User Research and Persona Development:/n We conduct thorough user research to gain insights into your target audience's needs, motivations, and behaviors. By creating user personas, we develop a deep understanding of your users, allowing us to design interfaces that cater to their specific requirements.`,
          `Information Architecture and Wireframing:/n Our team excels in information architecture, organizing content and functionalities in a logical and user-centric manner. Through wireframing, we create structural blueprints that define the layout, navigation, and hierarchy of your digital products, ensuring intuitive user experiences.`,
          `Visual Design and Prototyping:/n We combine aesthetics with functionality to create visually appealing and impactful interfaces. Our designers utilize the latest design trends, typography, color schemes, and imagery to craft engaging visual designs. Prototyping allows us to simulate user interactions, test the usability of designs, and gather feedback for iterative improvements.`,
          `Usability Testing and User Feedback Analysis:/n We conduct usability testing sessions to evaluate the effectiveness and efficiency of your interfaces. Through user feedback analysis, we gain valuable insights that help optimize designs, address usability issues, and refine user experiences for continuous improvement.`
        ]
      },
      {
        img: '/services/uiux3.png',
        title: 'Partnering for Exceptional User Experiences',
        content: [
          `Design Expertise and Innovation:/n Our team of skilled designers possesses a deep understanding of UI/UX design principles, industry best practices, and emerging trends. We combine creativity with technical expertise to deliver designs that engage users and drive business outcomes.`,
          `User-Centric Approach:/n We prioritize user needs and preferences throughout the design process. By conducting user research, usability testing, and incorporating user feedback, we ensure that our designs align with your users' expectations and deliver meaningful experiences.`,
          `Collaboration and Communication:/n We believe in collaborative partnerships with our clients. Our team works closely with you, involving you in the design process, actively seeking your input, and ensuring that the final UI/UX design represents your brand identity and aligns with your business objectives.`
        ]
      },
    ]
  },
]


export const industries = [
  {
    title: "Aerospace & Defence",
    content:
      "We offers technology solutions for aerospace and defence companies, such as network security, software development, and system integration.",
    img: "/industries/Aerospace.png",
    color: "#FF6B6B",
    icon: <FlightIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Automotive",
    content:
      "We provides technology solutions to the automotive industry, including data analytics, supply chain management, and customer relationship management (CRM) software.",
    img: "/industries/3d-car.png",
    color: "#76BA99",
    icon: <RvHookupIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Education",
    content: "We provides provides technology solutions to the education industry, including learning management systems (LMS), student information systems (SIS), and e-learning platforms.",
    img: "/industries/ddd.jpg",
    color: "#1BDE9C",
    icon: <SchoolIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Energy and Utilities",
    content: "We provides technology solutions to the energy and utilities industry, including smart grid management, renewable energy management, and predictive maintenance systems.",
    img: "/industries/3d-energy.png",
    color: "#8F63DF",
    icon: <BoltIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Farming and Agriculture",
    content: "We offers technology solutions to the farming and agriculture industry, including precision farming, crop management systems, and supply chain optimization.",
    img: "/industries/3d-dashboard.png",
    color: "#E8A0BF",
    icon: <GrassIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Finance",
    content: "We provides technology solutions to the finance industry, including risk management, fraud prevention, and trading systems.",
    img: "/industries/3d-finance.png",
    color: "#845EC2",
    icon: <AccountBalanceIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Healthcare",
    content: "We offers technology solutions to the healthcare industry, including electronic medical records (EMR) systems, telemedicine solutions, and data analytics for clinical research.",
    img: "/industries/ddd.jpg",
    color: "#72147E",
    icon: <SpaIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Manufacturing Industries",
    content: "We provides technology solutions to various manufacturing industries, including supply chain management, quality control systems, and enterprise resource planning (ERP) software.",
    img: "/industries/ddd.jpg",
    color: "#60C9C5",
    icon: <PrecisionManufacturingIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Retail",
    content: "We provides technology solutions to the retail industry, including point-of-sale (POS) systems, inventory management, and customer analytics.",
    img: "/industries/ddd.jpg",
    color: "#E5BA73",
    icon: <StorefrontIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Technology",
    content: "We provides technology solutions to the technology industry itself, including software development, cybersecurity, and cloud computing.",
    img: "/industries/ddd.jpg",
    color: "#6E85B7",
    icon: <SettingsSuggestIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
  {
    title: "Travel",
    content: "We offers technology solutions to the travel industry, including reservation systems, loyalty program management, and online booking platforms.",
    img: "/industries/ddd.jpg",
    color: "#A7727D",
    icon: <BookOnlineIcon sx={{
      // color:'#fff',
      width: [40, 40, 40, 50, 60,],
      height: [40, 40, 40, 50, 60,],
    }} />
  },
];


export const newIndustries = [
  { id: "/industries/space-technology", title: "Space Technology/ Aerospace", img: "/industries/space-technology/rocket-propulsion-design-software.avif" },
  { id: "", title: "Defence Technology", img: "/industries/defence-technology/tanker.png" },
  { id: "/industries/automotive", title: "Automotive", img: "/industries/automotive/automotive-manufacturing-process-simulation-software.jpg" },
  { id: "/industries/agriculture", title: "Agriculture", img: "/industries/agriculture/agribusiness-erp-and-financial-software.jpg" },
  { id: "/industries/education", title: "Education", img: "/industries/education/augmented-reality-educational-tools-for-stem-subjects.jpg" },
  { id: "/industries/energy-utilities", title: "Energy & Utilities", img: "/industries/energy-utilities/about3.jpg" },
  { id: "/industries/healthcare", title: "Healthcare", img: "/industries/healthcare/healthcare-robotics-and-automation-systems.jpg" },
  { id: "/industries/finance", title: "Finance", img: "/industries/finance/photorealistic-money-with-plant.jpg" },
  { id: "/industries/manufacturing", title: "Manufacturing", img: "/industries/manufacturing/product.avif" },
  { id: "/industries/retail", title: "Retail", img: "/industries/retail/digital-signage-and-in-store-experience-platforms.avif" },
  { id: "/industries/textile", title: "Textile", img: "/industries/textile/sustainable-fashion-design-software.jpg" },
  { id: "/industries/travel", title: "Travel", img: "/industries/travel/pexels-nubikini-386009.jpg" },
  { id: "/industries/technology", title: "Technology", img: "/industries/education/ai-powered.jpg" },
  { id: "/industries/chemical", title: "Chemical", img: "/industries/chemical/computational-chemistry-and-molecular-modeling-applications.jpg" },
  { id: "/industries/oil-and-gas", title: "Oil and Gas", img: "/industries/oil-and-gas/oil-and-gas-trading-and-risk-management-software.jpg" },
  { id: "/industries/ocean-technology", title: "Ocean Technology", img: "/industries/ocean-technology/pexels-maahidphotos-4701633.jpg" },
  { id: "/industries/logistics", title: "Logistics", img: "/industries/logistics/pexels-frans-van-heerden-201846-2881632.jpg" },
  { id: "/industries/infrastructure", title: "Infrastructure", img: "/industries/infrastructure/pexels-grizzlybear-399636.jpg" },
  { id: "/industries/transportation", title: "Transportation", img: "/industries/transportation/truck-logistics-operation-dusk.jpg" },
  { id: "/industries/sports", title: "Sports", img: "/industries/sports/sports-team-management-and-collaboration-software.jpg" },
  {
    id: "/industries/telecommunication",
    title: "Telecommunication",
    img: "/industries/telecommunication/young-network-engineer-with-ethernet-wires-medium-shot.jpg"
  },
  {
    id: "/industries/pharmaceutical",
    title: "Pharmaceutical",
    img: "/industries/pharmaceutical/about.avif"
  },
  {
    id: "/industries/entertainment-and-media",
    title: "Entertainment and Media",
    img: "/industries/entertainment-and-media/video.jpg"
  },
];