import {
  Box,
  Stack,
  Typography,
  Drawer,
  Toolbar,
  List,
  ListItem,
  Link,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShieldIcon from "@mui/icons-material/Shield";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import SchoolIcon from "@mui/icons-material/School";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import SpaIcon from "@mui/icons-material/Spa";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import StorefrontIcon from "@mui/icons-material/Storefront";
import StoreIcon from "@mui/icons-material/Store";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import MemoryIcon from "@mui/icons-material/Memory";
import ScienceIcon from "@mui/icons-material/Science";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import InventoryIcon from "@mui/icons-material/Inventory";
import ConstructionIcon from "@mui/icons-material/Construction";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CellTowerIcon from "@mui/icons-material/CellTower";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import MovieIcon from "@mui/icons-material/Movie";

const fadeAni = {
  offscreen: { opacity: 0 },
  onscreen: {
    opacity: 1,
  },
};
const drawerWidth = 240;
function MenuBar({ color, navColor, isScrollDown }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const newIndustries = [
    {
      id: "/industries/space-technology",
      title: "Space Technology/ Aerospace",
      icon: (
        <RocketLaunchIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "",
      title: "Defence Technology",
      icon: (
        <ShieldIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/automotive",
      title: "Automotive",
      icon: (
        <AutoModeIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/agriculture",
      title: "Agriculture",
      icon: (
        <AgricultureIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/education",
      title: "Education",
      icon: (
        <SchoolIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/energy-utilities",
      title: "Energy & Utilities",
      icon: (
        <EnergySavingsLeafIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/healthcare",
      title: "Healthcare",
      icon: (
        <SpaIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/finance",
      title: "Finance",
      icon: (
        <AccountBalanceIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/manufacturing",
      title: "Manufacturing",
      icon: (
        <PrecisionManufacturingIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/retail",
      title: "Retail",
      icon: (
        <StorefrontIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/textile",
      title: "Textile",
      icon: (
        <StoreIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/travel",
      title: "Travel",
      icon: (
        <LocalAirportIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/technology",
      title: "Technology",
      icon: (
        <MemoryIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/chemical",
      title: "Chemical",
      icon: (
        <ScienceIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/oil-and-gas",
      title: "Oil and Gas",
      icon: (
        <WaterDropIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/ocean-technology",
      title: "Ocean Technology",
      icon: (
        <DirectionsBoatIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/logistics",
      title: "Logistics",
      icon: (
        <InventoryIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/infrastructure",
      title: "Infrastructure",
      icon: (
        <ConstructionIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/transportation",
      title: "Transportation",
      icon: (
        <EmojiTransportationIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/sports",
      title: "Sports",
      icon: (
        <SportsSoccerIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/telecommunication",
      title: "Telecommunication",
      icon: (
        <CellTowerIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/pharmaceutical",
      title: "Pharmaceutical",
      icon: (
        <VaccinesIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
    {
      id: "/industries/entertainment-and-media",
      title: "Entertainment and Media",
      icon: (
        <MovieIcon
          sx={{
            fontSize: ["18px", "18px", "18px", "22px", "24px"],
            color: "#F74641 ",
          }}
        />
      ),
    },
  ];
  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  const changeNavStyle = () => {
    if (window.scrollY >= 90) {
      setIsDown(true);
    } else {
      setIsDown(false);
    }
  };
  window.addEventListener("scroll", changeNavStyle);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [titles] = useState([
    {
      id: "",
      name: "Home",
    },
    {
      id: "about",
      name: "About",
    },
    {
      id: "services",
      name: "Services",
    },
    {
      id: "industries",
      name: "Industries",
    },
    {
      id: "careers",
      name: "Careers",
    },
    {
      id: "contact-us",
      name: "Contact Us",
    },
  ]);

  const drawer = (
    <Stack direction="column" height={"100%"}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          pr: 2,
          position: "fixed",
          zIndex: "20",
        }}
      >
        {isIndustriesOpen && (
          <Stack
            sx={{
              bgcolor: "#18181873",
              p: 1,
              mr: 1,
              borderRadius: "2rem",
            }}
          >
            <ArrowBackIcon
              sx={{ color: "#fff" }}
              onClick={() => {
                setIsIndustriesOpen(false);
              }}
            />
          </Stack>
        )}

        <Stack
          sx={{
            bgcolor: "#18181873",
            p: 1,
            borderRadius: "2rem",
          }}
        >
          <CloseIcon
            sx={{ color: "#fff" }}
            onClick={() => {
              setMobileOpen(false);
            }}
          />
        </Stack>
      </Toolbar>
      {!isIndustriesOpen ? (
        <List
          component={motion.ul}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {titles.map((item, index) =>
            item.id == "industries" ? (
              <ListItem
                onClick={() => {
                  setIsIndustriesOpen(true);
                }}
                button
                sx={{ fontWeight: "600", 
                   background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent", }}
              >
                <ListItemText
                  sx={{
                    fontWeight: "medium",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disableTypography
                  primary={
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "1.3rem",
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              <Link
                href={`/${item.id}`}
                color={"inherit"}
                underline="none"
                key={index}
              >
                <ListItem
                  onClick={() => {
                    setMobileOpen(!mobileOpen);
                  }}
                  button
                  sx={{ fontWeight: "600", 
                     background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",}}
                >
                  <ListItemText
                    sx={{
                      fontWeight: "medium",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disableTypography
                    primary={
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "1.3rem",
                          textAlign: "center",
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
            )
          )}
        </List>
      ) : (
        <List
          component={motion.ul}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
          }}
        >
          {newIndustries.map((item, index) => (
            <Link
              href={`${item.id}`}
              color={"inherit"}
              underline="none"
              key={index}
            >
              <ListItem
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                }}
                button
                sx={{
                  fontWeight: "600",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",

                  mt: index == 0 ? 10 : 0,
                  mb: index +1 == newIndustries.length ? 4 : 0,
                }}
              >
                <ListItemText
                  sx={{
                    fontWeight: "medium",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disableTypography
                  primary={
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "1.3rem",
                        textAlign: "center",
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                />
              </ListItem>
            </Link>
          ))}
        </List>
      )}
    </Stack>
  );
  return (
    <>
      {/* menu icon */}
      <Stack
        sx={{
          position: "fixed",
          top: isScrollDown ? "0" : "-10rem",
          color: navColor ? navColor : isDown ? "#000" : "#fff",
          bgcolor: isDown ? "rgba(255,255,255,.3)" : null,
          backdropFilter: isDown ? "blur(25px)" : null,
          borderRadius: isDown ? "0 0 .5rem .5rem" : null,
          boxShadow: isDown ? 3 : null,
          zIndex: "300",
          transition: ".2s ease-in",
        }}
        display={{ xs: "flex", md: "none" }}
        py={{ xs: isDown ? ".8rem" : "1rem" }}
        p="1rem"
        direction="row"
        alignItems="center"
        top="0"
        width="100%"
        justifyContent="space-between"
      >
        <Link href={`/`} color="inherit" underline="none">
          <Box display="flex" flexDirection="column">
            <Typography
              fontWeight={500}
              fontSize={{ xs: "15px", sm: 15, md: 18, xl: 34 }}
            >
              TECHBRAIN
            </Typography>
            <Typography
              letterSpacing=".4rem"
              fontSize={{ xs: "7.5px", sm: 7.5, md: 10, xl: 27 }}
            >
              NETWORKS
            </Typography>
          </Box>
        </Link>
        <Stack
          display={{ xs: "flex", md: "none" }}
          onClick={() => {
            setMobileOpen(true);
          }}
        >
          <MenuIcon />
        </Stack>
      </Stack>
      {/* drawer */}
      <Box
        display={{ xs: "block", md: "none" }}
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          anchor="right"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              backgroundColor:'#080f25',
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* desktop screens */}
      <Stack
        top={{ xs: 0 }}
        sx={{
          position: "fixed",
          // top: "1.5rem",
          top: isScrollDown ? "0" : "-10rem",
          color: navColor ? navColor : isDown ? "#000" : "#fff",
          bgcolor: isDown ? "rgba(255,255,255,.3)" : null,
          backdropFilter: isDown ? "blur(25px)" : null,
          borderRadius: isDown
            ? { xs: "0 0 .8rem .8rem", xl: "0 0 2rem 2rem" }
            : null,
          boxShadow: isDown ? 3 : null,
          zIndex: "3000",
          transition: ".2s ease-in",
          fontWeight: "300",
        }}
        display={{ xs: "none", md: "flex" }}
        px={{ xs: 2, sm: 4, md: 5, lg: 10, xl: 12 }}
        py={{
          xs: 2,
          sm: isDown ? 2 : 2.5,
          md: isDown ? 2 : 3,
          lg: isDown ? 2 : 5,
          xl: isDown ? 2.5 : 5,
        }}
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        component={motion.div}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true, amount: 1 }}
        transition={{ staggerChildren: 0.5, delay: 0.4, duration: 1.5 }}
        variants={fadeAni}
      >
        <Link href={`/`} color="inherit" underline="none">
          <Box display="flex" flexDirection="column">
            <Typography
              fontWeight={700}
              fontSize={{ xs: "15px", sm: 17, md: 18, lg: 21, xl: 27 }}
            >
              TECHBRAIN
            </Typography>
            <Typography
              letterSpacing=".4rem"
              fontSize={{ xs: "7.5px", sm: 8.5, md: 10, lg: 13, xl: 20.5 }}
            >
              NETWORKS
            </Typography>
          </Box>
        </Link>
        <Box
          sx={{
            fontSize: [".6rem", ".9rem", ".9rem", "1rem", "1.5rem"],
            fontWeight: "300",
          }}
          display="flex"
          alignItems="center"
          gap={{ xs: "2rem", xl: "3rem" }}
        >
          <motion.a
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.95,
            }}
            href="/"
          >
            Home
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.95,
            }}
            href="/about"
          >
            About
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.95,
            }}
            href="/services"
          >
            Services
          </motion.a>

          <Box
            component={motion.div}
            initial={false}
            animate={isDropdownOpen ? "open" : "closed"}
            onMouseEnter={handleMouseEnter}
            onClick={() => setIsDropdownOpen((prev) => !prev)}
            position="relative"
          >
            <motion.a href=""
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              style={{ cursor: "pointer" }}
            >
              Industries
            </motion.a>
            <Box
              component={motion.div}
              onMouseLeave={handleMouseLeave}
              variants={{
                open: {
                  clipPath: "inset(0% 0% 0% 0% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                    delayChildren: 0.3,
                    staggerChildren: 0.05,
                  },
                },
                closed: {
                  clipPath: "inset(10% 50% 90% 50% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                  },
                },
              }}
              sx={{
                position: "absolute",
                // width: '100vw',
                pointerEvents: isDropdownOpen ? "auto" : "none",
                top: "150%",
                right: "-300%",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                borderRadius: "14px",
                p: "2px",
                zIndex: 1000,
                marginLeft: "-1rem",
                background:
                  "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
              }}
            >
              <Stack
                sx={{
                  borderRadius: "14px",
                  px: ["1rem", "1.4rem", "1.5rem", "2rem", "3rem"],
                  py: ["1rem", "1.4rem", "1.5rem", "2rem", "3rem"],
                  bgcolor: "#080f25",
                }}
              >
                <Stack
                  direction={{ xs: "row" }}
                  gap={{ xs: 1.2, sm: 4, md: 6 }}
                >
                  <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                    {newIndustries.slice(0, 6).map((item, index) => (
                      <IndustryItem item={item} key={index} />
                    ))}
                  </Stack>
                  <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                    {newIndustries.slice(6, 12).map((item, index) => (
                      <IndustryItem item={item} key={index} />
                    ))}
                  </Stack>

                  <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                    {newIndustries.slice(12, 18).map((item, index) => (
                      <IndustryItem item={item} key={index} />
                    ))}
                  </Stack>
                  <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                    {newIndustries.slice(18, 24).map((item, index) => (
                      <IndustryItem item={item} key={index} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
         
          <motion.a
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.95,
            }}
            href="/careers"
          >
            Careers
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.95,
            }}
            href="/contact-us"
          >
            Contact Us
          </motion.a>
        </Box>
      </Stack>
    </>
  );
}

export default MenuBar;

function IndustryItem({ item }) {
  return (
    <Stack
      direction={"row"}
      component={Link}
      href={item.id}
      color={"inherit"}
      underline="none"
      sx={{
        gap: [1],
        ":hover *": {
          color: "#F74641 ",
        },
      }}
    >
      {item.icon}
      <Typography
        sx={{
          fontSize: [".6rem", ".9rem", ".8rem", "1rem", "1.1rem"],
          whiteSpace: "nowrap",
          color: "#fff",
        }}
      >
        {item.title}
      </Typography>
    </Stack>
  );
}
